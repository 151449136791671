<!-- App.vue -->
<template>
  <div id="app">
    <nav class="navbar">
      <div class="navbar-left">
        <!-- Burger slide button and logo -->
        <Slide class="BurgerSlide" :width="auto" :margin-left="0" :text-align="left">
          <div class="burger-menu-content">
            <router-link class="burgerlink" to="HomePage">Home</router-link>
            <!-- <router-link class="burgerlink" to="/AboutPage">About</router-link> -->
            <router-link class="burgerlink" to="/3dprinting">{{ $t('3D-printen-router') }}</router-link>
            <router-link class="burgerlink" to="/Advies">{{ $t('Advies-router') }}</router-link>
            <router-link class="burgerlink" to="/Cursussen">{{ $t('Cursussen-router') }}</router-link>
            <router-link class="burgerlink" to="/Prints">{{ $t('Prints-router') }}</router-link>
            <router-link class="burgerlink" to="/Zwangerschap">{{ $t('Zwangerschap-scans-en-prints-router') }}</router-link>
            <router-link class="burgerlink" to="/Bustes">{{ $t('Bustes-router') }}</router-link>
            <router-link class="burgerlink" to="/3Dscannen">{{ $t('3D-Scannen-router') }}</router-link>
            <router-link class="burgerlink" to="/Assembleren">{{ $t('Assembleren-3D-printers-router') }}</router-link>
            <router-link class="burgerlink" to="/Onderhoud">{{ $t('Onderhoud-3D-printers-router') }}</router-link>
            <router-link class="burgerlink" to="/ContactPage">{{ $t('Contact-pagina-router') }}</router-link>
          </div>
        </Slide>
        <div class="logo-border">
          <div class="navbar-logo">
            <!-- <img class="logo" :src="require('@/assets/Wesley.png')" alt="Company Logo"> -->
            <H1 class="logo">Everything3D</H1>
          </div>
        </div>
      </div>
      <!-- Navbar text -->
      <!-- <div class="navbar-text">{{ $t('titel pagina') }}</div> -->
      <!-- Language flag buttons -->
      <div class="language-buttons">
        <button @click="changeLanguage('nl')">
          <img :src="require('@/assets/nl-flag.png')" alt="Nederlands" class="flag-icon">
        </button>
        <button @click="changeLanguage('en')">
          <img :src="require('@/assets/uk-flag.png')" alt="English" class="flag-icon">
        </button>
        <button @click="changeLanguage('de')">
          <img :src="require('@/assets/de-flag.png')" alt="Deutsch" class="flag-icon">
        </button>
      </div>
    </nav>
    <router-view/>
  </div>

  <footer class="footer">
<div class="footer-left">
  <h3>Everything3D</h3>
  <p> </p>
  <p>See contact page for contact</p>

  <p>Phone: 31 6 139 5280</p>
  <!-- <ul>
    <li><a href="#">About Us</a></li>
    <li><a href="#">Services</a></li>
    <li><a href="#">Contact</a></li>
    <li><a href="#">Privacy Policy</a></li>
  </ul> -->
</div>
<div class="footer-right">
  
  <a href="https://www.instagram.com/everything3d.nl" target="blank"><i class="fa fa-instagram" style="font-size:48px;color:white"></i></a>
  <p>&copy; 2024 Everything3D.</p>
  <div class="social-icons">
    <a href="#"><i class="fab fa-facebook-f"></i></a>
    <a href="#"><i class="fab fa-twitter"></i></a>
    <a href="#"><i class="fab fa-linkedin-in"></i></a>
    <a href=""><i class="fab fa-instagram"></i></a>
  </div>
</div>
</footer>

</template>

<script>
import { Slide } from 'vue3-burger-menu';

export default {
  name: 'App',
  components: {
    Slide,
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    }
  }
};
</script>

<style>
@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/myCustomFont.woff') format('woff'); /* Use relative path */
  font-weight: normal;
  font-style: normal;
}

#app, .navbar-text {
  font-family: 'MyCustomFont', Avenir, Helvetica, Arial, sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.navbar {
  background-color: black;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

.navbar-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

.navbar-logo {
  margin-left: 1rem;
}

.logo-border {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
}

.navbar-text {
  flex-grow: 1;
  text-align: center;
  color: #42b983; /* Default text color */
  font-size: 1.5rem; /* Adjust font size as needed */
}

.language-buttons {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

.flag-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.bm-item-list {
  color: #b8b7ad;
  margin-left: 0;
  font-size: 20px;
}

.logo {
  max-height: 50px;
  margin-left: 30px; /* Move the logo to the right */
  border: 2px solid black; /* Add border */
  border-radius: 10px; /* Add border radius */
  padding: 5px; /* Add padding for visual spacing */
}

.BurgerSlide {
  text-decoration-color: aliceblue;
  background-color: white;
  text-align: left;
}

.burgerlink {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.burgerlink:hover {
  text-decoration: underline;
}

.burger-menu-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.bm-burger-bars {
  background-color: #42b983;
}

.header {
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.text-center{
  text-align: center;
}

.additional-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.additional-text p {
  text-align: center;
}

h3 {
  color: #42b983; /* Set the color you want here */
  text-align: center;
}

.footer {
background-color: #000;
color: #fff;
padding: 20px 10px; /* Verkleind van 40px 20px naar 20px 10px */
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
bottom: 0;
border: #000 2px;
border-radius: 5px;
}


/* Media Queries for iPhone Resolutions */
@media (max-width: 400px) {
  .language-buttons {
    display: flex;
    justify-content: flex-end; /* Align to the right */
    align-items: center;
    gap: 10px;
    background-color: white;
    padding: 10px;
    border: 2px solid rgb(234, 13, 13);
    border-radius: 10px;
    flex-wrap: wrap; /* Ensure it wraps on smaller screens */
  }

  /* .navbar{
    width: 50%;
  } */
}

</style>

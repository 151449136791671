import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import router from './router'
import {createI18n} from 'vue-i18n'
import nl from './i18n/NL.json'
import en from './i18n/EN.json'
import de from './i18n/De.json'

const firebaseConfig = {
    apiKey: "AIzaSyAoS_aZLXdW-qzJvqm3nOI-CJjHfIrKgEs",
    authDomain: "test-koenvdv.firebaseapp.com",
    projectId: "test-koenvdv",
    storageBucket: "test-koenvdv.appspot.com",
    messagingSenderId: "178214582161",
    appId: "1:178214582161:web:1a4cbb1d1cc5fdffc04c30",
    measurementId: "G-TN89LVNRXY"
}

const i18n = createI18n({
    locale:'nl', 
    fallbacklocale: 'en',
    messages: {
        nl, 
        en, 
        de
    },
});

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

const db = getFirestore(firebaseApp)

createApp(App).provide('db', db).use(router).use(i18n).mount('#app')


